<!--PublishPostDialog:发布帖子弹窗 2024/07/28 10:26:01 huangmx-->

<template>
  <div class="publish-post-dialog">
    <el-dialog :title="$t('po')" :visible.sync="localVisible" @close="handleClose" :close-on-click-modal="false"
      :close-on-press-escape="false" width="712px">
      <div>
        <div class="mb-10">
          <el-input v-model="form.title" :placeholder="$t('postDialogTip1')" maxlength="30" show-word-limit></el-input>
        </div>
        <div class="mb-10">
          <el-input type="textarea" v-model="form.content" :placeholder="$t('postDialogTip2')" :rows="5"></el-input>
        </div>
        <div class="img-area mb-10">
          <div v-for="(item, index) in form.coverList" :key="'img_' + index" class="img-item"
            :style="{ backgroundImage: 'url(' + item + ')' }">
            <el-button type="danger" @click="delImg(index)" size="mini" icon="el-icon-delete" circle></el-button>
          </div>
        </div>
        <div class="upload-container mb-10">
          <el-upload action="#" :before-upload="beforeUpload" accept="image/jpg,image/jpeg,image/png,image/gif">
            <el-button :disabled="form.coverList.length >= 9" :loading="uploading" size="mini">
              <i v-if="!uploading" class="el-icon-upload el-icon--left"></i>
              <span>{{ uploading ? $t('uploading') : $t('addImg') }}</span>
            </el-button>
          </el-upload>
          <el-button icon="el-icon-collection-tag" size="mini" @click="showLabelDialog = true">{{ $t('selectLabel')
            }}</el-button>
        </div>
      </div>
      <div class="dialog-btn">
        <el-button type="primary" size="mini" @click="publishPost">{{ $t('publish') }}</el-button>
      </div>
    </el-dialog>
    <select-label-dialog :visible.sync="showLabelDialog" @ok="selectedLabels"></select-label-dialog>
  </div>
</template>
<script>
import { uploadFile } from '@/utils/aws'
import { articleApi, trackApi } from '@/utils/api'
import SelectLabelDialog from '@/views/components/SelectLabelDialog'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  name: 'PublishPostDialog',
  components: {
    // eslint-disable-next-line
    SelectLabelDialog, Editor, Toolbar
  },
  props: {
    visible: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      editor: null,
      html: null,
      toolbarConfig: {
        excludeKeys: ['group-image', 'insertVideo']
      },
      editorConfig: { placeholder: this.$t('postDialogTip2') },
      mode: 'simple',
      localVisible: false,
      showLabelDialog: false,
      uploading: false,
      uploadProgress: 0,
      form: {
        type: 'POST',
        title: '',
        summary: '',
        content: '',
        coverList: [],
        labelIds: [],
        classificationIds: []
      }
    }
  },
  created () {
    this.localVisible = this.visible
  },
  mounted () {
    // 更新Dom
  },
  watch: {
    visible: function (newValue) {
      this.localVisible = newValue
    }
  },
  methods: {
    onCreated (editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    handleClose: function () {
      this.localVisible = false
      this.$emit('close')
    },
    delImg: function (index) {
      this.form.coverList.splice(index, 1)
    },
    publishPost: function () {
      if (!this.checkParam()) {
        return
      }
      this.form.summary = this.form.content.substring(0, 200)
      articleApi.createArticle(this.form).then(resp => {
        console.log('save success')
        this.$message.success(this.$t('publishSuccess'))
        this.$emit('close')
        // 跳转到 社区列表页
        this.$router.push({ path: '/community' })
      })
      // 发布文章 埋点
      trackApi.articleTrack('PUBLISH_ARTICLE', null, this.$store.state.user.id, null)
    },
    checkParam: function () {
      if (!this.form.title) {
        this.$message.error(this.$t('inputTitle'))
        return false
      }
      if (!this.form.content) {
        this.$message.error(this.$t('inputContent'))
        return false
      }
      if (this.form.labelIds.length === 0) {
        this.$message.error(this.$t('selectCAndL'))
        return false
      }
      return true
    },
    selectedLabels: function (labels) {
      if (!this.form.labelIds && labels.length === 0) {
        return
      }
      console.log(labels)
      this.form.labelIds = labels.map(item => item.id)
      this.form.classificationIds = [...new Set(labels.map(item => item.classificationId))]
    },
    beforeUpload: function (file) {
      if (this.uploading) {
        this.$message.warn(this.$t('waiteMoment'))
        return
      }
      const isJPG = /\.(jpe?g|png|gif)$/i.test(file.name)

      if (!isJPG) {
        this.$message.error(this.$t('onlyFormat'))
        return
      }
      this.doUpload(file)
    },
    doUpload: function (file) {
      this.uploading = true
      uploadFile(file, 'front/post', (loaded, total) => {
        this.uploadProgress = Math.round((loaded / total) * 100)
        console.log(this.uploadProgress)
      }).then(res => {
        this.uploading = false
        this.uploadProgress = 0
        this.form.coverList.push(res.Location)
      }).catch(error => {
        this.uploading = false
        console.error('Upload failed:', error)
      })
    }
  }
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
.mb-10 {
  margin-bottom: 10px;
}

.img-area {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.img-item {
  width: 85px;
  height: 85px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 14px;
  margin-right: 25px;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
}

.dialog-btn {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-bottom: 20px;
}

.upload-container {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.publish-post-dialog>>>.el-dialog__wrapper .el-dialog {
  border-radius: 14px;
  padding: 5px;
}

.publish-post-dialog>>>.el-dialog__wrapper .el-dialog__header {
  text-align: left;
}

.img-item>>>.el-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
